<div id="landing" class="section center row">

  <div id="landing-left" class="fill-space column center">
    <div class="column column-center-left">
      <div id="landing-logo"> <img src="assets/images/logo.svg"/> </div>
      <div id="landing-title">  Be Smart.   Hire Smart.    </div>
      <div id="landing-subtitle"> AI Resume Analysis and Candidate Matching for Modern HR </div>
      <div id="landing-text">
        HireFox is an all-in-one, AI-powered recruitment solution that streamlines the hiring process by parsing, analyzing, and evaluating resumes,
        and organizing them into a searchable database. HireFox leverages AI to extract critical data from each resume, deliver personalized insights,
        and identify the best candidate matches for each job position. With HireFox, you're not just saving valuable time; you're empowering your HR team
        to make data-driven decisions, simplify their workflow, and focus on what they do best: connecting the right talent with the right opportunity.
      </div>
      <div id="landing-buttons">
        <a [href]="urlService.calendar" target="_blank">
          <div class="landing-button"> See It In Action By Booking A Demo </div>
        </a>
      </div>
    </div>
  </div>
  <div id="landing-right" class="fill-space column center">
    <div id="landing-image"> <img src="assets/images/landing-image.svg"/> </div>
  </div>



</div>
