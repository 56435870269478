<div id="analysis" class="section center">

  <div class="row center">
    <div class="section-left column center">
      <div id="analysis-image" class="section-image"> <img src="assets/images/analysis-image.svg"/> </div>
    </div>
    <div id="analysis-content" class="section-right column">
      <div class="title">Advanced AI-Powered Resume Analysis </div>
      <div class="subtitle"> Say goodbye to manual resume screening! </div>
      <div class="text">
        Hirefox's cutting-edge AI technology extracts essential data from candidate resumes, regardless of format (e.g., PDF, Word, etc.),
        providing valuable insights for HR departments. By automating resume analysis, our platform helps you identify the most qualified
        candidates without the need for time-consuming manual review.
      </div>
      <div class="list column">
        <div class="list-item">
          <div class="list-item-icon"> <img src="assets/images/arrow-right-orange.svg"/> </div>
          <div class="list-item-text"> Automated Data Extraction </div>
        </div>
        <div class="list-item">
          <div class="list-item-icon"> <img src="assets/images/arrow-right-orange.svg"/> </div>
          <div class="list-item-text"> Powerful Insights </div>
        </div>
        <div class="list-item">
          <div class="list-item-icon"> <img src="assets/images/arrow-right-orange.svg"/> </div>
          <div class="list-item-text"> Process Hundreds of Resumes In A Few Minutes </div>
        </div>
      </div>
      <div class="flex">  <cta-button text="See It In Action"></cta-button> </div>
    </div>
  </div>


</div>
