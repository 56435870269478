import { Component } from '@angular/core';
import {MenuService} from "../../../Services/menu.service";

@Component({
  selector: 'Menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})

export class MenuComponent {

  menu: MenuService;

  constructor(private menuService: MenuService) {
    this.menu = menuService;
  }


}
