import { Component } from '@angular/core';

@Component({
  selector: 'job-matcher',
  templateUrl: './job-matcher.component.html',
  styleUrls: ['./job-matcher.component.css']
})
export class JobMatcherComponent {

}
