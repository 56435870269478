import { Component } from '@angular/core';

@Component({
  selector: 'candidate-profiling',
  templateUrl: './candidate-profiling.component.html',
  styleUrls: ['./candidate-profiling.component.css']
})
export class CandidateProfilingComponent {

}
