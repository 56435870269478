import { Component } from '@angular/core';

@Component({
  selector: 'candidate-analysis',
  templateUrl: './candidate-analysis.component.html',
  styleUrls: ['./candidate-analysis.component.css']
})
export class CandidateAnalysisComponent {

}
