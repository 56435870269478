<div id="resume-formatting" class="section column center content-section">

  <div class="row">
    <div class="section-right column column-center-left content-section-info">
      <div class="title"> Attention to Detail Matters </div>
      <div class="subtitle"> Spotting the Small Touches that Signal Big Potential </div>
      <div class="text">
        In every resume, it's the small details that often hint at a candidate's true potential.
        HireFox is engineered to identify and highlight these critical nuances, ensuring nothing slips through the cracks.
        Our tool scrutinizes for accuracy in spelling, precision in formatting, and the appropriate use of terminology.
        It even notes the subtler signs of a candidate's care and diligence, such as the consistency of their layout and
        the clarity of their communication. These seemingly minor elements can differentiate a meticulous professional
        from the rest. With HireFox, you're empowered to make hiring decisions not only on the broad strokes but also
        on the fine lines that define excellence
      </div>
    </div>
    <div class="section-left center">
      <div  class="section-image content-section-image"> <img src="assets/images/attention-to-detail.png"/> </div>
    </div>
  </div>

</div>
