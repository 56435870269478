import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  calendar: string = "https://calendly.com/hirefox-demo";

  constructor() { }
}
