<div id="candidate-profiling" class="section column center content-section">

  <div class="row">
    <div class="section-left center">
      <div  class="section-image content-section-image"> <img src="assets/images/indicators.png"/> </div>
    </div>
    <div class="section-right column column-center-left content-section-info">
      <div class="title"> Insightful Indicators </div>
      <div class="subtitle"> At-a-Glance Evaluation: Red and Green Candidate Flags </div>
      <div class="text">
        Our system intelligently analyzes each applicant's details to raise red or green flags, offering a powerful snapshot
        of potential concerns or commendable highlights. These flags range from signaling diverse backgrounds and consistent
        employment to spotting career gaps or job hopping trends. With this feature, you gain an immediate, visual understanding
        of each candidate's profile, helping you to quickly discern which applicants might require further review and which
        ones stand out as top contenders. Make your hiring decisions with the benefit of nuanced, data-driven insights that
        go beyond the resume, right at your fingertips with HireFox.
      </div>
    </div>
  </div>

</div>
