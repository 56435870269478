<div id="candidate-profiling" class="section column center content-section">

  <div class="row">
    <div class="section-left center">
      <div  class="section-image content-section-image"> <img src="assets/images/candidate-profiling.png"/> </div>
    </div>
    <div class="section-right column column-center-left content-section-info">
      <div class="title"> Effortless Candidate Profiling </div>
      <div class="subtitle"> Skip the Calculations, Get the Facts </div>
      <div class="text">
        HireFox automatically extracts key information such as total years of experience, job tenure,
        seniority level, and education level without manual calculations or guesswork.
        It identifies career patterns like gaps in employment and traces career progression and focus,
        delivering these insights directly to you. This not only saves considerable time for reviewers but
        also ensures a consistent and unbiased evaluation of every candidate. With HireFox, you can invest more
        time engaging with candidates and less time deciphering their histories, streamlining your
        hiring process to be as efficient as it is effective
      </div>
    </div>
  </div>

</div>
