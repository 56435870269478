import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BannerComponent } from './common/banner/banner.component';
import { LogoComponent } from './common/banner/logo/logo.component';
import { MenuComponent } from './common/banner/menu/menu.component';
import { HomeComponent } from './pages/home/home.component';
import { PageComponent } from './common/page/page.component';
import {Router} from "@angular/router";
import { LandingComponent } from './pages/home/landing/landing.component';
import { AboutComponent } from './pages/home/about/about.component';
import { BenchmarksComponent } from './pages/home/benchmarks/benchmarks.component';
import { AnalysisComponent } from './pages/home/analysis/analysis.component';
import { DatabaseComponent } from './pages/home/database/database.component';
import { JobMatcherComponent } from './pages/home/job-matcher/job-matcher.component';
import { LinkedinComponent } from './pages/home/linkedin/linkedin.component';
import { FooterComponent } from './common/footer/footer.component';
import { NewsletterComponent } from './pages/home/newsletter/newsletter.component';
import { GlanceComponent } from './pages/home/glance/glance.component';
import { FeaturesComponent } from './pages/features/features.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { CandidateAnalysisComponent } from './pages/features/candidate-analysis/candidate-analysis.component';
import { CandidateProfilingComponent } from './pages/features/candidate-profiling/candidate-profiling.component';
import { ResumeFormattingComponent } from './pages/features/resume-formatting/resume-formatting.component';
import { IndicatorsComponent } from './pages/features/indicators/indicators.component';
import { AttentionToDetailComponent } from './pages/features/attention-to-detail/attention-to-detail.component';
import { NewsletterSignUpComponent } from './common/newsletter-sign-up/newsletter-sign-up.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {MatDialogModule} from "@angular/material/dialog";
import { LoaderComponent } from './common/loader/loader.component';
import { PipelineComponent } from './pages/home/pipeline/pipeline.component';
import { CtaButtonComponent } from './common/cta-button/cta-button.component';
import { DemoComponent } from './pages/demo/demo.component';
import * as Sentry from "@sentry/angular";


@NgModule({
  declarations: [
    AppComponent,
    BannerComponent,
    LogoComponent,
    MenuComponent,
    HomeComponent,
    PageComponent,
    LandingComponent,
    AboutComponent,
    BenchmarksComponent,
    AnalysisComponent,
    DatabaseComponent,
    JobMatcherComponent,
    LinkedinComponent,
    FooterComponent,
    NewsletterComponent,
    GlanceComponent,
    FeaturesComponent,
    PricingComponent,
    CandidateAnalysisComponent,
    CandidateProfilingComponent,
    ResumeFormattingComponent,
    IndicatorsComponent,
    AttentionToDetailComponent,
    NewsletterSignUpComponent,
    LoaderComponent,
    PipelineComponent,
    CtaButtonComponent,
    DemoComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
