import { Component } from '@angular/core';

@Component({
  selector: 'attention-to-detail',
  templateUrl: './attention-to-detail.component.html',
  styleUrls: ['./attention-to-detail.component.css']
})
export class AttentionToDetailComponent {

}
