<div id="candidate-analysis" class="section column center content-section">

  <div class="row">
    <div class="section-right column column-center-left content-section-info">
      <div class="title"> In-Depth Candidate Analysis </div>
      <div class="subtitle"> Clear Insights for Better Hiring Decisions </div>
      <div class="text">
        Assess candidates effectively with HireFox's Detailed Candidate Analysis.
        Our AI-driven system evaluates applicants across multiple dimensions — experience, skills, education,
        and more — to give you a complete view of their potential.
        For each section, we provide a clear comparison between the candidate's abilities and the average scores,
        tailored to the job they're applying for. This means you can see at a glance not just how a candidate fares overall,
        but also how they stand out in the areas that matter most for your specific needs. With HireFox, you're equipped to
        make smarter hiring decisions, backed by data that's both comprehensive and easy to understand.
      </div>
    </div>
    <div class="section-left center">
      <div  class="section-image content-section-image"> <img src="assets/images/candidate-analysis.png"/> </div>
    </div>
  </div>

</div>
