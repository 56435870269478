<div id="home" class="page column">
  <landing></landing>
  <glance></glance>
  <analysis></analysis>
  <database></database>
  <job-matcher></job-matcher>
  <pipeline></pipeline>
  <!--<linkedin></linkedin>
  <benchmarks></benchmarks>-->
</div>
