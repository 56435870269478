<div id="resume-formatting" class="section column center content-section">

  <div class="row">
    <div class="section-right column column-center-left content-section-info">
      <div class="title"> Unified Resume Formatting </div>
      <div class="subtitle"> Say goodbye to the chaos of navigating through countless layouts and designs </div>
      <div class="text">
        Transform the way you review resumes with HireFox's Unified Resume Formatting.
        Say goodbye to the chaos of navigating through countless layouts and designs.
        Our platform standardizes every resume into a consistent format, ensuring that the
        information you need is exactly where you expect it, every time. This uniformity allows for
        quicker comparisons and evaluations, freeing you from the frustration of inconsistent resume structures.
        With all key details presented clearly, you can focus on what truly matters—the candidates' qualifications.
        HireFox is here to simplify your hiring process, making it more efficient and less time-consuming
      </div>
    </div>
    <div class="section-left center">
      <div  class="section-image content-section-image"> <img src="assets/images/resume-formatting.png"/> </div>
    </div>
  </div>

</div>
