import {Component, Input} from '@angular/core';
import {UrlService} from "../../Services/url.service";

@Component({
  selector: 'cta-button',
  templateUrl: './cta-button.component.html',
  styleUrls: ['./cta-button.component.css']
})
export class CtaButtonComponent {

  @Input() text: string = "Book A Demo";

  constructor(public urlService: UrlService) {
  }

}
