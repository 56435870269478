<div id="banner-wrapper" class="">
  <div id="banner" class="fill-space">
    <logo class="column center"></logo>
    <Menu class="center"></Menu>
    <div class="fill-space"></div>
    <div class="center">
      <a [href]="urlService.calendar" target="_blank">
        <div class="button-cta"> Book A Demo </div>
      </a>
    </div>
  </div>
</div>

