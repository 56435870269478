import { Component } from '@angular/core';

@Component({
  selector: 'resume-formatting',
  templateUrl: './resume-formatting.component.html',
  styleUrls: ['./resume-formatting.component.css']
})
export class ResumeFormattingComponent {

}
