<div id="database" class="section column center">

  <div class="row">
    <div id="database-content" class="section-left column column-center-left">
      <div class="title">A Well Organized, Easily Searchable Database </div>
      <div class="subtitle"> Advanced Resume Database Management with Powerful Filtering Capabilities </div>
      <div class="text">
        With Hirefox, you can quickly build a powerful, searchable database of candidate resumes.
        Simply upload your resume files, and our AI will parse and organize them for easy filtering and searching.
        Find the perfect candidate for any position in seconds with our user-friendly interface and advanced search capabilities.
      </div>
      <div class="list column">
        <div class="list-item">
          <div class="list-item-icon"> <img src="assets/images/arrow-right-green.svg"/> </div>
          <div class="list-item-text"> Seamless Search </div>
        </div>
        <div class="list-item">
          <div class="list-item-icon"> <img src="assets/images/arrow-right-green.svg"/> </div>
          <div class="list-item-text"> Advanced Filtering </div>
        </div>
      </div>
      <div class="flex"> <cta-button text="Schedule Your Demo Today"></cta-button> </div>
    </div>
    <div class="section-right center-vertical">
      <div id="database-image" class="section-image"> <img src="assets/images/database-image.svg"/> </div>
    </div>
  </div>

</div>
