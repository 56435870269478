import { Injectable } from '@angular/core';

export interface IMenuItem {
  name: string;
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  pages: IMenuItem[] = [
    { name: 'home', url: 'home'},
    { name: 'features', url: 'features'},
    { name: 'pricing', url: 'pricing'}
  ];

  currentPage: string = "home";

  constructor() { }
}
