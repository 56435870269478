import { Component } from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {NewsletterSignUpComponent} from "../newsletter-sign-up/newsletter-sign-up.component";
import {UrlService} from "../../Services/url.service";

@Component({
  selector: 'Banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent {

  constructor(private dialog: MatDialog, public urlService: UrlService) {
  }

  showNewsletterSignUp(): void {
    this.dialog.open(NewsletterSignUpComponent, {});
  }

}
